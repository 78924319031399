@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
@font-face {
  font-family: "Montserrat";
  src: url(/src/fonts/Montserrat/Montserrat-VariableFont_wght.ttf);
}
body {
  margin: 0;
  font-family: "Montserrat", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(1,13,38);
  background: linear-gradient(90deg, rgba(1,13,38,1) 0%, rgba(6,55,91,1) 69%, rgba(0,124,149,0.7540266106442577) 100%);
}
